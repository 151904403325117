import axios from "axios";
// import { getItem } from '../util/localStorage'
import { apiUrl } from "../config/config";
import { duration } from "moment";
import moment from "moment";

export const apiService = {
    login,
    verifyOtp,
    addproduct,
    updateproduct,
    get_products,
    get_productDetail,
    get_productImages,
    Dashboard,
    ChangeStatus,
    changePassword,
    get_users,
    get_usersDash,
    update_status,
    transaction_list,
    contactUs,
    AddCategory,
    UpdateCategory,
    DeleteCategory,
    GetCategoryList,
    acceptReject,
    get_user_detail,
    deleteImage,
    statusChange,
    AddUnit,
    GetUnitList,
    DeleteUnit,
    getOrders,
    getOrderDetail,
    settingUpdate,
    settingGet,
    getBank,
    orderTransaction,
    changeLanguage,
    addVC,
    allget_users,
    getPayout,

 }


const headers =() =>
    {
        let token = localStorage.getItem("admin_token")
        let lang = localStorage.getItem("lang")
       return {headers : { 'Authorization': `Bearer ${token}`,"role":"ADMIN","Accept-Language":lang}} 
    };
    const headersLogin =() =>
    {
        let token = localStorage.getItem("admin_token")
        let lang = localStorage.getItem("lang")
       return {headers : { 'Authorization': `Bearer ${token}`,"role":"ADMIN","Accept-Language":lang }} 
    };
//    console.log('headers()....', headers());
async function login(data) {
    return await axios.post(`${apiUrl}/login`, data,headersLogin());
}

async function verifyOtp(data) {
  return await axios.post(`${apiUrl}/login`, data,headersLogin());
}

async function statusChange(id) {
  return await axios.patch(`${apiUrl}/product/`+id,{},headers());
}

async function addproduct(params,images) {
  console.log("images====",images)
  let data = new FormData()
  if(params.id){
    let productdata = await axios.post(`${apiUrl}/product`, params,headers());
 
     data.append("productId",params.id)
    if(images.length>0){
      images.map((image,index)=>{
          data.append("files",image)
      })
      data.append("type","image")
      return await axios.post(`${apiUrl}/product/file/upload`, data,headers());
  }else{
    return productdata
  }
  }else{
  let productdata = await axios.post(`${apiUrl}/product`, params,headers());

  data.append("productId",productdata.data.data.resource.id)
  if(images.length>0){
    images.map((image,index)=>{
        data.append("files",image)
    })
   
      data.append("type","image")
     return await axios.post(`${apiUrl}/product/file/upload`, data,headers());
    }else{
      return productdata
    }

  }
  
}
async function updateproduct(params,id,image,startDate) {
  let data = new FormData()
 let timestamp = toTimestamp(startDate)
  data.append("question",params.question)
  data.append("description",params.description)
  data.append("startDateTime",timestamp)
  data.append("duration",params.duration)
  data.append("threshold_time",params.threshold_time)
  data.append("outcome",params.outcome)
  if(image!=undefined){
   data.append("image",image)
  }
  return await axios.put(`${apiUrl}/admin/edit-prediction/`+id, data,headers());
}
 const toTimestamp = (strDate) => {
  const dt = moment.utc(strDate).unix();
  return dt;
}
async function get_products(page,size,categoryId,name=""){
  let params;
  if(name.trim()!=""){
     params= {
      "page":page,
      "size":size,
      "categoryId":categoryId,
       "name":name
      }
  }else{
    params= { "page":page,
    "size":size,
    "categoryId":categoryId
    
    }
  }
 
    
  
  return await axios.post(`${apiUrl}/product/filter`, params,headers() )
}
async function get_productDetail(id){
  return await axios.get(`${apiUrl}/product/`+id, headers())
}
async function get_productImages(id){
  return await axios.get(`${apiUrl}/product/file/list/`+id, headers())
}
async function changePassword(data){
  return await axios.post(`${apiUrl}/password/change/`,data, headers())
}
async function get_users(page,size,role,search,enablestatus){
  let params= {
    "page":page,
    "size":size,
    "role":role,
     "search":search,
     "enabled":enablestatus
    }
    console.log("====params=====",params)
  return await axios.post(`${apiUrl}/user/filter`,params, headers())
}
async function get_usersDash(page,size,status){
  let params= {
    "page":page,
    "size":size,
    "status":status,
    
    }
    console.log("====params=====",params)
  return await axios.post(`${apiUrl}/user/filter`,params, headers())
}
async function update_status(id,data){
  return await axios.put(`${apiUrl}/admin/user-status/${id}`,data, headers())
}
async function transaction_list(page,address,walletaddress){
  return await axios.get(`${apiUrl}/admin/transaction-list?page=`+page+"&contract_address="+address+"&wallet_address="+walletaddress, headers())
}

async function Dashboard(){
    return await axios.get(`${apiUrl}/admin/account/dashboard`, headers())
}
// async function ChangeStatus(id,params){
//   return await axios.put(`${apiUrl}/change_user_status/${id}/`, params, headers())
// }

// async function contactUs(page){
//   return await axios.get(`${apiUrl}/admin/contactUs-list?page=`+page, headers())
// }

async function AddCategory(params){
  return await axios.post(`${apiUrl}/category/`, params, headers())
}

async function GetCategoryList(){
  return await axios.get(`${apiUrl}/category/filter`,headers())
}
async function DeleteCategory(id){
  return await axios.delete(`${apiUrl}/category/` + id, headers())
}
async function AddUnit(params){
  return await axios.post(`${apiUrl}/unit/`, params, headers())
}

async function GetUnitList(params){
  return await axios.get(`${apiUrl}/unit`,headers())
}
async function UpdateCategory(params, id){
  return await axios.put(`${apiUrl}/category/` + id , params, headers() )
}
async function DeleteUnit(id){
  return await axios.delete(`${apiUrl}/unit/` + id, headers())
}
 

async function acceptReject(id,status,deactivatedReason){

  return await axios.put(`${apiUrl}/admin/account/status/${id}?status=`+status+"&reason="+deactivatedReason,{},headers())
  
}
 
async function ChangeStatus(id){
  return await axios.put(`${apiUrl}/user/toggle-active/${id}`,{},headers())
  
}
async function get_user_detail(id){
  return await axios.get(`${apiUrl}/user/${id}`,headers())
}


async function deleteImage(id){
  return await axios.delete(`${apiUrl}/product/file/image/${id}`,headers())
}


async function contactUs(page,size){
  return await axios.get(`${apiUrl}/contact-us/filter/page/${page}/size/${size}`,headers())
}



async function getOrders(page,size,status,search){
  let params= {
    "page":page,
    "size":size,
    "status":status,
    "search":search

    }
  
  return await axios.post(`${apiUrl}/order/filter`, params,headers() )
}



async function getOrderDetail(id){
  return await axios.get(`${apiUrl}/order/${id}?commission=true`,headers() )
 }
 async function settingUpdate(params){
  return await axios.post(`${apiUrl}/settings`,params,headers() )
 }
 async function settingGet(){
  return await axios.get(`${apiUrl}/settings`,headers() )
 }



 async function getBank(userid){

  return await axios.get(`${apiUrl}/bank/detail/${userid}`,headers() )
 }

 async function orderTransaction(page,size,queryParam){
  console.log(queryParam)
  return await axios.get(`${apiUrl}/user-order-transactions/page/${page}/size/${size}${queryParam}

  `,headers() )
 }
 async function changeLanguage(lang){
  let token = localStorage.getItem("admin_token")
  let language_headers= {headers : {  'Authorization': `Bearer ${token}`,"role":"ADMIN","Accept-Language":lang}}
   return await axios.put(`${apiUrl}/user/language`,{},language_headers )
  }
  async function addVC(params){
    return await axios.post(`${apiUrl}/virtual-credits`,params,headers() )
   }

   async function allget_users(){
    return await axios.get(`${apiUrl}/user`,headers() )
   }

   async function getPayout(page,size,type){
    let params= {
      "page":page,
      "size":size,
      "role":type
  
      }
    
    return await axios.post(`${apiUrl}/payout/pending`, params,headers() )
  }